.ChonkyBday {
    transition: opacity 1s ease-in-out;
}

.ChonkyBday .Intro {
    top: 20%;
    padding: 0px 12px;
}

.video-player {
    top: calc(calc(100% - 533px) / 2) !important;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.fade-out {
    opacity: 1;
    animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.hidden {
    display: none;
}